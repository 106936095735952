<template>
  <div>
    <VTitle :title="$t('app.license_users')" class="route-title" />

    <VList
      :rows="displayedRows"
      :headers="headers"
      display-pagination
      :is-loading="isLoading"
      :pagination="pagination"
      @update:current_page="onUpdateCurrentPage"
      @update:per_page="onUpdatePerPage"
    >
      <template #add-button>
        <div />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.image="{ item }">
        <VImage :src="item.displayed_avatar" :name="item.displayed_name" />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.user="{ item }">
        <div class="cursor-pointer" @click="onClickUpdate(item.id)">
          <div class="font-medium whitespace-nowrap">
            {{ item.displayed_name }}
          </div>
          <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
            @{{ item.username }}
          </div>
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="item.displayed_status"
            :class="getStatusColor(item.status)"
          />
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.__ACTIONS="{ item }">
        <div class="flex justify-center">
          <VAction icon="edit" @click="onClickUpdate(item.id)" />
        </div>
      </template>
    </VList>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useDisplay from "@/composables/useDisplay";
import useUser from "@/composables/useUser";
import useEntityUsers from "@/composables/useEntityUsers";
import useColor from "@/composables/useColor";
// Components
import VList from "@/components/tables/VList";
import VTitle from "@/components/VTitle";
import VAction from "@/components/tables/VAction";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";

export default {
  components: {
    VList,
    VTitle,
    VAction,
    VImage,
    VChip
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  setup(props) {
    // MISC
    const router = useRouter();
    const { t } = useI18n();

    // CONSTANTS
    const endpoint = "administration.licenses";
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.users", 1),
        value: "user"
      },
      {
        text: t("app.phone"),
        value: "phone"
      },
      {
        text: t("app.email"),
        value: "email"
      },
      {
        text: t("app.status"),
        value: "status",
        class: "w-40"
      },
      {
        text: t("app.actions"),
        value: "__ACTIONS",
        class: "w-56"
      }
    ];
    const documentTitle = `${t("app.users", 2)} - ${t("app.licenses", 2)} - ${t(
      "app.administration"
    )}`;

    // CUSTOM COMPOSABLES
    const { getStatusColor } = useColor();
    const { getAvatarURL } = useDisplay();
    const { route } = useUser();
    const {
      getData,
      rows,
      isLoading,
      pagination,
      onUpdateCurrentPage,
      onUpdatePerPage
    } = useEntityUsers(props, { endpoint });

    // COMPUTED
    const displayedRows = computed(() => {
      return rows.value.map(row => ({
        ...row,
        displayed_name: `${row.firstname} ${row.lastname}`,
        displayed_avatar: getAvatarURL(row?.avatar?.view_path),
        displayed_status: row.status ? t(`app.${row.status}`) : ""
      }));
    });

    // METHODS
    const onClickUpdate = id => {
      router.push({
        name: `${route}-update`,
        params: { id }
      });
    };

    return {
      displayedRows,
      headers,
      documentTitle,
      getStatusColor,
      onClickUpdate,
      pagination,
      onUpdateCurrentPage,
      onUpdatePerPage,
      getData,
      rows,
      isLoading
    };
  }
};
</script>
